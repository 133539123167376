import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'

function NotFound() {
    return (
        <div className="mentions__container" id="hello">
            <div className="mentions__container__div">
                <img
                    src={imgA}
                    alt="Fond de montagne"
                    className="Prestation__container__div__img"
                ></img>
            </div>
            <div className="notFound__container">
                <h1 className="notFound__container__h1">Aïe!...404</h1>
                <h2 className="notFound__container__h2">
                    Quelque chose c'est mal passé
                </h2>
                <p className="notFound__container__p">
                    Cette page n'existe pas ou a été supprimée.
                </p>
                <a href="/" className="notFound__container__a">
                    Revenir à l'accueil
                </a>
            </div>
        </div>
    )
}

export default NotFound
