function UpLink() {
    return (
        <div className="upLink__div" id="UpLink">
            <a href="#hello" className="upLink__div__btn">
                <i className="fa-sharp fa-solid fa-chevron-up"></i>
                <span className="sr-only">revenir en haut de la page</span>
            </a>
        </div>
    )
}

export default UpLink
