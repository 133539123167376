import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'
import imgB from '../../Assets/Social_Profile.webp'
import imgC from '../../Assets/20944999_prev_ui.webp'
import imgD from '../../Assets/20943659_prev_ui.webp'

function About() {
    return (
        <div className="AboutUs__container" id="hello">
            <div className="AboutUs__container__div">
                <img
                    src={imgA}
                    alt="Fond de montagne"
                    className="AboutUs__container__div__img"
                ></img>
            </div>
            <h1 className="AboutUs__container__h1">A propos</h1>
            <section className="AboutUs__container__section1">
                <div className="AboutUs__container__section1__wrapper">
                    <div className="AboutUs__container__section1__wrapper__divA">
                        <img
                            src={imgC}
                            alt="illustration de bureau"
                            className="AboutUs__container__section1__wrapper__divA__img"
                        ></img>
                        <div className="AboutUs__container__section1__wrapper__divA__div">
                            <h2 className="AboutUs__container__section1__wrapper__divA__div__h2">
                                Notre entreprise
                            </h2>
                            <p className="AboutUs__container__section1__wrapper__divA__div__p">
                                Chez GT-Atweb, nous sommes fiers de proposer des{' '}
                                <b>services de développement web</b> uniques et
                                personnalisés. En tant qu'
                                <b>entreprise individuelle</b>, je suis le seul{' '}
                                <b>développeur web</b> à bord et cela me permet
                                de m'engager personnellement à chaque projet. Je
                                suis en mesure de consacrer tout mon temps et
                                mon expertise pour créer des{' '}
                                <b>solutions sur mesure</b> qui répondent
                                parfaitement à vos besoins.
                            </p>
                        </div>
                    </div>
                    <div className="AboutUs__container__section1__wrapper__divD">
                        <h2 className="AboutUs__container__section1__wrapper__divD__h2">
                            Qui suis-je
                        </h2>
                        <p className="AboutUs__container__section1__wrapper__divD__p">
                            Je suis Teddy Guerrand, un jeune{' '}
                            <b>développeur web</b> diplômé passionné par le{' '}
                            <b>développement front-end</b> et <b>back-end</b>{' '}
                            que ce soit en <b>JavaScript</b>, <b>React</b> ou
                            encore <b>Node.js</b> ou <b>MySQL</b>. Je suis
                            également capable de travailler sur le{' '}
                            <b>back-end</b>. Je suis déterminé à mettre mes
                            compétences au service de votre entreprise pour
                            créer des <b>projets innovants</b> et à impacts
                            positifs.
                        </p>
                    </div>
                    <div className="AboutUs__container__section1__wrapper__divB">
                        <h2 className="AboutUs__container__section1__wrapper__divB__h2">
                            Nos engagements
                        </h2>
                        <p className="AboutUs__container__section1__wrapper__divB__p">
                            GT-Atweb s'engage à :
                        </p>
                        <ul className="AboutUs__container__section1__wrapper__divB__ul">
                            <li className="AboutUs__container__section1__wrapper__divB__ul__li">
                                Offrir des <b>services de développement web</b>{' '}
                                de qualité supérieure en utilisant les dernières
                                technologies et les meilleures pratiques du
                                secteur.
                            </li>
                            <li className="AboutUs__container__section1__wrapper__divB__ul__li">
                                Écouter attentivement les besoins de nos clients
                                pour créer des <b>solutions sur mesure</b> qui
                                répondent à leurs exigences spécifiques.
                            </li>
                            <li className="AboutUs__container__section1__wrapper__divB__ul__li">
                                Maintenir une communication ouverte et
                                transparente avec nos clients tout au long du
                                processus de développement pour garantir leurs
                                satisfactions.
                            </li>
                            <li className="AboutUs__container__section1__wrapper__divB__ul__li">
                                Proposer un suivi et une <b>maintenance</b> pour
                                assurer un bon fonctionnement à long terme des
                                projets développés.
                            </li>
                        </ul>
                    </div>
                    <div className="AboutUs__container__section1__wrapper__divC">
                        <div className="AboutUs__container__section1__wrapper__divC__div">
                            <h2 className="AboutUs__container__section1__wrapper__divC__div__h2">
                                Pourquoi nous choisir
                            </h2>
                            <p className="AboutUs__container__section1__wrapper__divC__div__p">
                                En choisissant <b>GT-Atweb</b>, vous bénéficiez
                                de la <b>flexibilité</b> et de l'attention
                                personnelle d'un{' '}
                                <b>développeur web indépendant</b>, tout en
                                obtenant des résultats de qualité.
                            </p>
                        </div>
                        <img
                            src={imgD}
                            alt="Illustration d'entreprise"
                            className="AboutUs__container__section1__wrapper__divC__img"
                        ></img>
                    </div>
                </div>
                <aside className="AboutUs__container__section1__wrapper__aside">
                    <figure className="AboutUs__container__section1__wrapper__aside__figure">
                        <img
                            src={imgB}
                            alt="Teddy Guerrand"
                            className="AboutUs__container__section1__wrapper__aside__figure__img"
                        />
                    </figure>
                    <div className="AboutUs__container__section1__wrapper__aside__div">
                        <h2 className="AboutUs__container__section1__wrapper__aside__div__h2">
                            Développeur Web{' '}
                        </h2>
                        <h3 className="AboutUs__container__section1__wrapper__aside__div__name">
                            Teddy Guerrand
                        </h3>
                        <p className="AboutUs__container__section1__wrapper__aside__div__p">
                            Je crée des solutions web adaptées pour convertir
                            vos visiteurs en clients.
                        </p>
                        <h3 className="AboutUs__container__section1__wrapper__aside__div__h3">
                            Mes compétences
                        </h3>
                        <ul className="AboutUs__container__section1__wrapper__aside__div__ul">
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                Html
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                CSS
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                SASS
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                Javascript
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                React
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                Node.js
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                Mongo.DB
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li">
                                MySQL
                            </li>
                        </ul>
                        <h3 className="AboutUs__container__section1__wrapper__aside__div__h3">
                            Mes réseaux
                        </h3>
                        <ul className="AboutUs__container__section1__wrapper__aside__div__ul">
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li--sm">
                                <a
                                    href="https://www.linkedin.com/in/teddy-guerrand/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="AboutUs__container__section1__wrapper__aside__div__ul__li__a"
                                >
                                    <i className="fa-brands fa-linkedin AboutUs__container__section1__wrapper__aside__div__ul__li__a__i"></i>
                                    <span className="sr-only">LinkedIn</span>
                                </a>
                            </li>
                            <li className="AboutUs__container__section1__wrapper__aside__div__ul__li--sm">
                                <a
                                    className="AboutUs__container__section1__wrapper__aside__div__ul__li__a"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://github.com/TeddyGrd"
                                >
                                    <i className="fa-brands fa-github AboutUs__container__section1__wrapper__aside__div__ul__li__a__i"></i>
                                    <span className="sr-only">GitHub</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <a
                        href="/Contact"
                        className="AboutUs__container__section1__wrapper__aside__a"
                    >
                        Contactez-nous !
                    </a>
                </aside>
            </section>
        </div>
    )
}

export default About
