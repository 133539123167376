import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'
import React, { useRef } from 'react'
import emailjs from 'emailjs-com'

function Contact() {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()
        const notificationDiv = document.getElementById('notification')
        const errorNotification = document.getElementById('error-notification')
        const loader = document.getElementById('loader')
        const sendButton = document.getElementById('sendBtn')
        const Texte = document.getElementsByClassName(
            'contact__container__section2__wrapper__form__div__input'
        )
        const Message = document.getElementsByClassName(
            'contact__container__section2__wrapper__form__div__textarea'
        )
        errorNotification.style.display = 'none'
        sendButton.style.display = 'none'
        loader.style.display = 'block'
        emailjs
            .sendForm(
                `${process.env.REACT_APP_EMAIL_SERVICE}`,
                `${process.env.REACT_APP_EMAIL_TEMPLATE}`,
                form.current,
                `${process.env.REACT_APP_KEY_EMAIL}`
            )
            .then(
                (result) => {
                    if (result.text === 'OK') {
                        loader.style.display = 'none'
                        notificationDiv.style.display = 'block'
                        for (let i = 0; i < Texte.length; i++) {
                            Texte[i].value = ''
                            Message[0].value = ''
                        }
                    }
                },
                (error) => {
                    if (error.text !== 'OK') {
                        loader.style.display = 'none'
                        errorNotification.style.display = 'block'
                    }
                }
            )
    }

    return (
        <div className="contact__container" id="hello">
            <div className="contact__container__div">
                <img
                    src={imgA}
                    alt="Fond de montagne"
                    className="contact__container__div__img"
                ></img>
            </div>
            <h1 className="contact__container__h1">Contactez-nous</h1>
            <section className="contact__container__section">
                <div className="contact__container__section__social">
                    <div className="contact__container__section__social__div">
                        <i className="fa-solid fa-location-dot contact__container__section__social__div__ia"></i>
                        <h2 className="contact__container__section__social__div__h2">
                            Adresse
                        </h2>
                        <p className="contact__container__section__social__div__p">
                            9 Impasse des Palmiers
                        </p>
                        <p className="contact__container__section__social__div__p">
                            76610, Le Havre, France
                        </p>
                    </div>
                    <div className="contact__container__section__social__div">
                        <i className="fa-solid fa-phone contact__container__section__social__div__i"></i>
                        <h2 className="contact__container__section__social__div__h2">
                            Téléphone
                        </h2>
                        <p className="contact__container__section__social__div__p">
                            +33 6 69 57 22 56
                        </p>
                    </div>
                    <div className="contact__container__section__social__div">
                        <i className="fa-solid fa-envelope contact__container__section__social__div__i"></i>
                        <h2 className="contact__container__section__social__div__h2">
                            Adresse-mail
                        </h2>
                        <a
                            href="mailto:Contact@gt-atweb.com"
                            className="contact__container__section__social__div__a"
                        >
                            Contact@gt-atweb.com
                        </a>
                    </div>
                </div>
            </section>
            <section className="contact__container__section2">
                <div className="contact__container__section2__wrapper">
                    <form
                        className="contact__container__section2__wrapper__form"
                        ref={form}
                        onSubmit={sendEmail}
                    >
                        <div className="contact__container__section2__wrapper__form__div">
                            <label
                                htmlFor="name"
                                className="contact__container__section2__wrapper__form__div__label"
                            >
                                Nom / dénomination social * :
                            </label>
                            <input
                                type="text"
                                name="name"
                                placeholder=" Entrez votre Nom"
                                id="name"
                                required
                                className="contact__container__section2__wrapper__form__div__input"
                            ></input>
                        </div>
                        <div className="contact__container__section2__wrapper__form__div">
                            <label
                                htmlFor="Email"
                                className="contact__container__section2__wrapper__form__div__label"
                            >
                                Email* :
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="Email"
                                required
                                placeholder=" Entrez votre email"
                                className="contact__container__section2__wrapper__form__div__input"
                            ></input>
                        </div>
                        <div className="contact__container__section2__wrapper__form__div">
                            <label
                                htmlFor="numero"
                                className="contact__container__section2__wrapper__form__div__label"
                            >
                                Téléphone :
                            </label>
                            <input
                                type="tel"
                                name="telephone"
                                id="numero"
                                placeholder=" Entrez votre téléphone"
                                className="contact__container__section2__wrapper__form__div__input"
                            ></input>
                        </div>
                        <div className="contact__container__section2__wrapper__form__div">
                            <label
                                htmlFor="message"
                                className="contact__container__section2__wrapper__form__div__label"
                            >
                                Message* :
                            </label>
                            <textarea
                                required
                                type="text"
                                name="message"
                                id="message"
                                placeholder="Entrez votre message"
                                className="contact__container__section2__wrapper__form__div__textarea"
                            ></textarea>
                        </div>
                        <div className="contact__container__section2__wrapper__form__submit">
                            <button
                                type="submit"
                                id="sendBtn"
                                className="contact__container__section2__wrapper__form__submit__btn"
                            >
                                Envoyer
                            </button>
                            <div id="notification">
                                <p>Votre message a bien été envoyé.</p>
                            </div>
                            <div id="loader" className="loader"></div>
                            <div id="error-notification">
                                <p>Une erreur c'est produite </p>{' '}
                                <button
                                    className="error-notification__button"
                                    type="submit"
                                >
                                    réessayer
                                </button>
                                <p>ou</p>
                                <a href="mailto:Contact@gt-atweb.com">
                                    boîte mail
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="contact__container__section2__gmap">
                    <p className="contact__container__section2__gmap__p">
                        Aucun besoin d'être à proximité nous pouvons travailler
                        en full-remote{' '}
                        <i className="fa-regular fa-face-smile-wink"></i>.
                    </p>
                    <iframe
                        className="contact__container__section2__gmap__iframe"
                        title="emplacement de la société"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2590.735052587338!2d0.14959511566325506!3d49.508397179356436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e02f9569cf354f%3A0x3f2bc87c83169e55!2s9%20Imp.%20des%20Palmiers%2C%2076610%20Le%20Havre!5e0!3m2!1sfr!2sfr!4v1674150877294!5m2!1sfr!2sfr"
                        width="600"
                        height="550"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </section>
        </div>
    )
}

export default Contact
