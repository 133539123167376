function Footer() {
    return (
        <footer className="footer">
            <div className="footer__copyright">
                <div className="footer__copyright__triangle__top"></div>
                <p className="footer__copyright__p">
                    © Copyright 2024 - Tout droits réservés
                </p>
            </div>
            <div className="footer__container">
                <div className="footer__container__wrapper">
                    <div className="footer__container__wrapper__div">
                        <h2 className="footer__container__wrapper__div__h4">
                            En savoir plus
                        </h2>
                        <ul className="footer__container__wrapper__div__ul">
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/Mentions-legales"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Mentions légales
                                </a>
                            </li>
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/CGV"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Conditions Générales de Ventes
                                </a>
                            </li>
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/Politique-de-confidentialite"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Politique de confidentialité
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__container__wrapper__div">
                        <h2 className="footer__container__wrapper__div__h4">
                            Navigation
                        </h2>
                        <ul className="footer__container__wrapper__div__ul">
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Accueil
                                </a>
                            </li>
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/Nos-prestations"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Nos Prestations
                                </a>
                            </li>
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/A-propos"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    A propos
                                </a>
                            </li>
                            <li className="footer__container__wrapper__div__ul__li">
                                <a
                                    href="/Contact"
                                    className="footer__container__wrapper__div__ul__li__a"
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__container__wrapper__div">
                        <h2 className="footer__container__wrapper__div__h4">
                            Suivez-nous
                        </h2>
                        <div className="footer__container__wrapper__div__social">
                            <a
                                href="https://www.facebook.com/GT-Atweb-105385519215549"
                                target={'_blank'}
                                rel="noreferrer"
                                className="footer__container__wrapper__div__social__a"
                            >
                                <i className="fab fa-facebook-f"></i>
                                <span className="sr-only">Facebook</span>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/gt-atweb/"
                                target={'_blank'}
                                rel="noreferrer"
                                className="footer__container__wrapper__div__social__a"
                            >
                                <i className="fab fa-linkedin"></i>
                                <span className="sr-only">LinkedIn</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
