import React, { useRef } from 'react'
import Img from '../../Assets/WhiteLogoNB.webp'

function Header() {
    window.addEventListener('scroll', function () {
        let header = document.querySelector('header')
        let img = document.getElementById('logo')
        let upLink = document.getElementById('UpLink')
        header.classList.toggle('sticky', window.scrollY > 0)
        img.classList.toggle('imgResize', window.scrollY > 0)
        upLink.classList.toggle('upLink__div--visibility', window.scrollY > 0)
    })

    const navRef = useRef()
    const showNavbar = () => {
        navRef.current.classList.toggle('responsive_nav')
    }
    return (
        <header className="header__container">
            <a href="/" className="header__container__logo">
                <img
                    src={Img}
                    id="logo"
                    alt="logo GT-Atweb"
                    className="header__container__logo__img "
                ></img>
            </a>
            <nav className="header__container__nav" ref={navRef}>
                <ul className="header__container__nav__ul">
                    <li className="header__container__nav__ul__li">
                        <a
                            href="/"
                            className="header__container__nav__ul__li__link"
                        >
                            Accueil
                        </a>
                    </li>
                    <li className="header__container__nav__ul__li">
                        <a
                            href="/Nos-prestations"
                            className="header__container__nav__ul__li__link"
                        >
                            Nos Prestations
                        </a>
                    </li>
                    <li className="header__container__nav__ul__li">
                        <a
                            href="/a-propos"
                            className="header__container__nav__ul__li__link"
                        >
                            A Propos
                        </a>
                    </li>
                    <li className="header__container__nav__ul__li">
                        <a
                            href="/Contact"
                            className="header__container__nav__ul__li__link"
                        >
                            Contact
                        </a>
                    </li>
                </ul>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <i className="fa-solid fa-xmark"></i>
                    <span className="sr-only">
                        Fermer le menu de navigation
                    </span>
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <i className="fa-solid fa-bars"></i>
                <span className="sr-only">Ouvrir le menu de navigation</span>
            </button>
        </header>
    )
}

export default Header
