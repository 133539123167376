import React from 'react'
import Routes from './Components/Routes/Routes'
import ReactGA from 'react-ga4'

const App = () => {
    const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home' })
    ReactGA.send({ hitType: 'pageview', page: '/Contact', title: 'Contact' })
    ReactGA.send({ hitType: 'pageview', page: '/A-propos', title: 'A propos' })
    ReactGA.send({
        hitType: 'pageview',
        page: '/Nos-prestations',
        title: 'Prestations',
    })
    return <Routes />
}

export default App
