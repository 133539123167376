import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'
import imgB from '../../Assets/20944108_prev_ui.webp'
import imgC from '../../Assets/20943839_prev_ui.webp'
import imgD from '../../Assets/20945385_prev_ui.webp'
import imgE from '../../Assets/20943748_prev_ui.webp'
import imgF from '../../Assets/20945386_prev_ui.webp'

function Prestation() {
    return (
        <div className="Prestation__container" id="hello">
            <div className="Prestation__container__div">
                <img
                    src={imgA}
                    alt="fond de montagne"
                    className="Prestation__container__div__img"
                ></img>
            </div>
            <h1 className="Prestation__container__h1">Nos prestations</h1>
            <section className="Prestation__container__section">
                <div className="Prestation__container__section__divL">
                    <img
                        src={imgC}
                        className="Prestation__container__section__divL__img"
                        alt="Illustration Site vitrine"
                    ></img>
                    <div className="Prestation__container__section__divL__div">
                        <h2 className="Prestation__container__section__divL__div__h2">
                            Conception de site vitrine
                        </h2>
                        <div className="Prestation__container__section__divL__div__wrapper">
                            <p className="Prestation__container__section__divL__div__wrapper__p">
                                Nous proposons des services de{' '}
                                <b>création de sites web vitrines</b>{' '}
                                <b>sur mesure</b>, adaptés aux besoins uniques
                                de chaque client. Un <b>site web performant</b>{' '}
                                est essentiel pour augmenter la{' '}
                                <b>visibilité de votre entreprise</b>. Nous
                                créons des sites web en respectant les{' '}
                                <b>standards du W3C</b> pour garantir une{' '}
                                <b>qualité optimale</b>.
                            </p>
                            <ul className="Prestation__container__section__divL__div__wrapper__ul">
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Wordpress / Sur-mesure
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Design responsive
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Référencement naturel (SEO)
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Hébergement Web
                                </li>
                            </ul>
                            <div className="Prestation__container__section__divL__div__wrapper__div">
                                <a
                                    href="/contact"
                                    className="Prestation__container__section__divL__div__wrapper__div__a"
                                >
                                    Contactez-nous !
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Prestation__container__section__divR">
                    <img
                        src={imgB}
                        className="Prestation__container__section__divR__img"
                        alt="Illustration Site E-Commerce"
                    ></img>
                    <div className="Prestation__container__section__divR__div">
                        <h2 className="Prestation__container__section__divR__div__h2">
                            Conception de site e-commerce
                        </h2>
                        <div className="Prestation__container__section__divR__div__wrapper">
                            <p className="Prestation__container__section__divR__div__wrapper__p">
                                Nous proposons des <b>sites e-commerce</b>{' '}
                                <b>personnalisés</b> <b>sur mesure</b> ou basés
                                sur WordPress pour répondre à vos besoins
                                spécifiques. Faites-nous confiance pour créer
                                votre <b>boutique en ligne</b> et vous permettre
                                de facilement faire évoluer votre{' '}
                                <b>site web</b> à mesure que votre entreprise
                                grandit. Nos <b>services de création de</b>{' '}
                                <b>sites web e-commerce</b> sont adaptés à votre
                                entreprise.
                            </p>
                            <ul className="Prestation__container__section__divR__div__wrapper__ul">
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Wordpress / Sur-mesure
                                </li>
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Design responsive
                                </li>
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Référencement naturel (SEO)
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Sécurité
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Hébergement Web
                                </li>
                            </ul>
                            <div className="Prestation__container__section__divR__div__wrapper__div">
                                <a
                                    href="/contact"
                                    className="Prestation__container__section__divR__div__wrapper__div__a"
                                >
                                    Contactez-nous !
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Prestation__container__section__divL">
                    <img
                        src={imgD}
                        className="Prestation__container__section__divL__img"
                        alt="Illustration Maintenance de site web"
                    ></img>
                    <div className="Prestation__container__section__divL__div">
                        <h2 className="Prestation__container__section__divL__div__h2">
                            Maintenance de site web
                        </h2>
                        <div className="Prestation__container__section__divL__div__wrapper">
                            <p className="Prestation__container__section__divL__div__wrapper__p">
                                Notre entreprise offre des{' '}
                                <b>services de maintenance de sites web</b>{' '}
                                <b>personnalisés</b>, développés sur mesure ou
                                basés sur <b>WordPress</b>, afin de répondre aux
                                besoins spécifiques de chaque client. Faire des
                                maintenances de site web garantit la{' '}
                                <b>sécurité</b>, la <b>performance</b> et la
                                fonctionnalité de votre site et offre ainsi une{' '}
                                <b>expérience utilisateur</b> de qualité à vos
                                visiteurs.
                            </p>
                            <ul className="Prestation__container__section__divL__div__wrapper__ul">
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Mise a jour de sécurité
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Correction de bug
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Mise a jour du contenue
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Sauvegarde du site
                                </li>
                            </ul>
                            <div className="Prestation__container__section__divL__div__wrapper__div">
                                <a
                                    href="/contact"
                                    className="Prestation__container__section__divL__div__wrapper__div__a"
                                >
                                    Contactez-nous !
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Prestation__container__section__divR">
                    <img
                        src={imgF}
                        className="Prestation__container__section__divR__img"
                        alt="Illustration refonte de site web"
                    ></img>
                    <div className="Prestation__container__section__divR__div">
                        <h2 className="Prestation__container__section__divR__div__h2">
                            Refonte d'un site web
                        </h2>
                        <div className="Prestation__container__section__divR__div__wrapper">
                            <p className="Prestation__container__section__divR__div__wrapper__p">
                                Notre entreprise offre des{' '}
                                <b>
                                    services de refonte de sites web
                                    personnalisés
                                </b>
                                , que ce soit en partant de zéro ou en utilisant
                                <b> WordPress</b>, pour répondre aux besoins
                                spécifiques de chaque client. Nous pouvons vous
                                aider à envisager une refonte de votre site web
                                afin d'améliorer son apparence, sa convivialité
                                et sa pertinence en fonction des{' '}
                                <b>tendances actuelles</b> du marché et des{' '}
                                <b>besoins de vos utilisateurs</b>.
                            </p>
                            <ul className="Prestation__container__section__divR__div__wrapper__ul">
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Wordpress / Sur-mesure
                                </li>
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Design responsive
                                </li>
                                <li className="Prestation__container__section__divR__div__wrapper__ul__li">
                                    Référencement naturel (SEO)
                                </li>
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li">
                                    Sécurité
                                </li>
                            </ul>
                            <div className="Prestation__container__section__divR__div__wrapper__div">
                                <a
                                    href="/contact"
                                    className="Prestation__container__section__divR__div__wrapper__div__a"
                                >
                                    Contactez-nous !
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Prestation__container__section__divL">
                    <img
                        src={imgE}
                        className="Prestation__container__section__divL__img"
                        alt="Illustration Projet web"
                    ></img>
                    <div className="Prestation__container__section__divL__div">
                        <h2 className="Prestation__container__section__divL__div__h2">
                            Autre projet web
                        </h2>
                        <div className="Prestation__container__section__divL__div__wrapper">
                            <p className="Prestation__container__section__divL__div__wrapper__p">
                                Nous concevons des{' '}
                                <b>projets web personnalisés</b> pour répondre
                                aux besoins uniques de chaque client, que ce
                                soit pour des <b>sites vitrines</b>, des{' '}
                                <b>blogs</b> ou des{' '}
                                <b>applications web complexes</b>. Faire votre
                                projet web avec nous vous permettra d'atteindre
                                vos objectifs en ligne et de vous offrir à vous
                                ou votre public une{' '}
                                <b>expérience personnalisée</b>.
                            </p>
                            <ul className="Prestation__container__section__divL__div__wrapper__ul">
                                <li className="Prestation__container__section__divL__div__wrapper__ul__li"></li>
                            </ul>
                            <div className="Prestation__container__section__divL__div__wrapper__div">
                                <a
                                    href="/contact"
                                    className="Prestation__container__section__divL__div__wrapper__div__a"
                                >
                                    Contactez-nous !
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="Prestation__container__section__tarif">
                <p className="Prestation__container__section__tarif__p">
                    Notre tarif journaliers TTC:
                    <span className="Prestation__container__section__tarif__span">
                        300€*
                    </span>
                </p>
                <p className="Prestation__container__section__tarif__star">
                    *Indicatif des prix moyen sur le site{' '}
                    <a
                        className="Prestation__container__section__tarif__a"
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.malt.fr/t/barometre-tarifs/tech"
                    >
                        Malt.fr
                    </a>
                </p>
            </div>
        </div>
    )
}

export default Prestation
