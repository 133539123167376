import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'

function Mentions() {
    return (
        <div className="mentions__container" id="hello">
            <div className="mentions__container__div">
                <img
                    src={imgA}
                    alt="fond de montagne"
                    className="Prestation__container__div__img"
                ></img>
            </div>
            <section className="mentions__container__section">
                <div className="mentions__container__section__div">
                    <h1 className="mentions__container__section__div__h1">
                        Mentions Légales
                    </h1>
                    <h2 className="mentions__container__section__div__h2">
                        1. édition du site.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin
                        2004 pour la confiance dans l'économie numérique, il est
                        précisé aux utilisateurs du site internet{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        l'identité des différents intervenants dans le cadre de
                        sa réalisation et de son suivi:
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Propriétaire du site :</strong> Entrepreneur
                        individuel: Guerrand Teddy - société : GT-Atweb -
                        Contact :{' '}
                        <a
                            href="mailto:Contact@gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            Contact@gt-atweb.com
                        </a>{' '}
                        +33 669572256 - Adresse : 9 impasse des palmiers, 76610,
                        Le Havre, Seine-Maritime, Normandie , FR.
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Identification de l'entreprise :</strong> EI
                        Entrepreneur individuel: Guerrand Teddy - société :
                        GT-Atweb -{' '}
                        <span className="mentions__container__section__div__p__span">
                            SIREN : 948 806 492
                        </span>{' '}
                        - RCS ou RM : 948 806 492 R.C.S. LE HAVRE - Adresse
                        postale : 9 impasse des palmiers, 76610, Le Havre,
                        Seine-Maritime, Normandie , FR
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Directeur de la publication :</strong> Guerrand
                        Teddy - Contact :{' '}
                        <a
                            href="mailto:Contact@gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            Contact@gt-atweb.com
                        </a>
                        .
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Hébergeur :</strong> O2Switch - 222 Boulevard
                        Gustave Flaubert, 63000 Clermont-Ferrand - Téléphone :
                        04.44.44.60.40
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Délégué à la protection des données :</strong>{' '}
                        Guerrand Teddy -{' '}
                        <a
                            href="mailto:Contact@gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            Contact@gt-atweb.com
                        </a>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Crédit des images d'illustration :</strong>{' '}
                        <a
                            href="https://www.freepik.com/author/vectorjuice"
                            className="mentions__container__section__div__p__a"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Image by vectorjuice
                        </a>{' '}
                        on Freepik, Image by{' '}
                        <a
                            className="mentions__container__section__div__p__a"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.freepik.com/free-photo/flat-lay-desktop-with-laptop-magnifier_6740155.htm#query=officewithlaptop&position=9&from_view=search&track=ais"
                        >
                            Freepik
                        </a>
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        2. Propriété intellectuelle et contrefaçons.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Entrepreneur individuel : Guerrand Teddy - société :
                        GT-Atweb est propriétaire des droits de propriété
                        intellectuelle et détient les droits d’usage sur tous
                        les éléments accessibles sur le site internet, notamment
                        les textes, images, graphismes, logos, vidéos,
                        architecture, icônes et sons.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Toute reproduction, représentation, modification,
                        publication, adaptation de tout ou partie des éléments
                        du site, quel que soit le moyen ou le procédé utilisé,
                        est interdite, sauf autorisation écrite préalable de
                        Entrepreneur individuel: Guerrand Teddy - société :
                        GT-Atweb.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Toute exploitation non autorisée du site ou de l’un
                        quelconque des éléments qu’il contient sera considérée
                        comme constitutive d’une contrefaçon et poursuivie
                        conformément aux dispositions des articles L.335-2 et
                        suivants du Code de Propriété Intellectuelle.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        3. Limitations de responsabilité.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Entrepreneur individuel: Guerrand Teddy - société :
                        GT-Atweb ne pourra être tenu pour responsable des
                        dommages directs et indirects causés au matériel de
                        l’utilisateur, lors de l’accès au site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        .
                    </p>
                    <p className="mentions__container__section__div__p">
                        Entrepreneur individuel: Guerrand Teddy - société :
                        GT-Atweb décline toute responsabilité quant à
                        l’utilisation qui pourrait être faite des informations
                        et contenus présents sur{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>
                        .
                    </p>
                    <p className="mentions__container__section__div__p">
                        Entrepreneur individuel: Guerrand Teddy - société :
                        GT-Atweb s’engage à sécuriser au mieux le site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        , cependant sa responsabilité ne pourra être mise en
                        cause si des données indésirables sont importées et
                        installées sur son site à son insu.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Des espaces interactifs (espace contact ou commentaires)
                        sont à la disposition des utilisateurs. Entrepreneur
                        individuel: Guerrand Teddy - société : GT-Atweb se
                        réserve le droit de supprimer, sans mise en demeure
                        préalable, tout contenu déposé dans cet espace qui
                        contreviendrait à la législation applicable en France,
                        en particulier aux dispositions relatives à la
                        protection des données.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Le cas échéant, Entrepreneur individuel: Guerrand Teddy
                        - société : GT-Atweb se réserve également la possibilité
                        de mettre en cause la responsabilité civile et/ou pénale
                        de l’utilisateur, notamment en cas de message à
                        caractère raciste, injurieux, diffamant, ou
                        pornographique, quel que soit le support utilisé (texte,
                        photographie …).
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        4. CNIL et gestion des données personnelles.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Conformément aux dispositions de la loi 78-17 du 6
                        janvier 1978 modifiée, l’utilisateur du site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        dispose d’un droit d’accès, de modification et de
                        suppression des informations collectées. Pour exercer ce
                        droit, envoyez un message à notre Délégué à la
                        Protection des Données : Guerrand Teddy -{' '}
                        <a
                            href="mailto:Contact@gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            Contact@gt-atweb.com
                        </a>
                        .
                    </p>
                    <p className="mentions__container__section__div__p">
                        Pour plus d'informations sur la façon dont nous traitons
                        vos données (type de données, finalité,
                        destinataire...), lisez notre{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com/politique-de-confidentialite
                        </a>{' '}
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        5. Liens hypertextes et cookies
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        contient des liens hypertextes vers d’autres sites et
                        dégage toute responsabilité à propos de ces liens
                        externes ou des liens créés par d’autres sites vers{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>
                        .
                    </p>
                    <p className="mentions__container__section__div__p">
                        La navigation sur le site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        est susceptible de provoquer l’installation de cookie(s)
                        sur l’ordinateur de l’utilisateur.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Un "cookie" est un fichier de petite taille qui
                        enregistre des informations relatives à la navigation
                        d’un utilisateur sur un site. Les données ainsi obtenues
                        permettent d'obtenir des mesures de fréquentation, par
                        exemple.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Vous avez la possibilité d’accepter ou de refuser les
                        cookies en modifiant les paramètres de votre navigateur.
                        Aucun cookie ne sera déposé sans votre consentement.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Les cookies sont enregistrés pour une durée maximale de
                        1 mois. Pour plus d'informations sur la façon dont nous
                        faisons usage des cookies, lisez notre{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com/politique-de-confidentialité
                        </a>{' '}
                        .
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        6. Droit applicable et attribution de juridiction.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Tout litige en relation avec l’utilisation du site{' '}
                        <a
                            href="https://www.gt-atweb.com"
                            className="mentions__container__section__div__p__a"
                        >
                            https://www.gt-atweb.com
                        </a>{' '}
                        est soumis au droit français. En dehors des cas où la
                        loi ne le permet pas, il est fait attribution exclusive
                        de juridiction aux tribunaux compétents de Le Havre.
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Mentions
