import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from '../Header/Header'
import Home from '../../Pages/Home/Home'
import Prestation from '../../Pages/Prestation/Prestation'
import Contact from '../../Pages/Contact/Contact'
import About from '../../Pages/About-us/About'
import Mentions from '../../Pages/Mentions/Mentions'
import Cgv from '../../Pages/Cgv/Cgv'
import Pdc from '../../Pages/Pdc/Pdc'
import Footer from '../Footer/Footer'
import NotFound from '../../Pages/NotFound/NotFound'
import UpLink from '../UpLink/UpLink'

const index = () => {
    return (
        <Router>
            <Header />
            <UpLink />
            <Routes>
                <Route exact path="/" element={<Home></Home>} />
                <Route exact path="/Contact" element={<Contact></Contact>} />
                <Route exact path="/A-propos" element={<About></About>} />
                <Route
                    exact
                    path="/Mentions-legales"
                    element={<Mentions></Mentions>}
                />
                <Route
                    exact
                    path="/Nos-prestations"
                    element={<Prestation></Prestation>}
                />
                <Route
                    exact
                    path="/Politique-de-confidentialite"
                    element={<Pdc></Pdc>}
                />
                <Route exact path="/CGV" element={<Cgv></Cgv>} />
                <Route path="*" element={<NotFound></NotFound>} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default index
