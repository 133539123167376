import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'

function Pdc() {
    return (
        <div className="mentions__container" id="hello">
            <div className="mentions__container__div">
                <img
                    src={imgA}
                    alt="fond de montagne"
                    className="Prestation__container__div__img"
                ></img>
            </div>
            <section className="mentions__container__section">
                <div className="mentions__container__section__div">
                    <h1 className="mentions__container__section__div__h1">
                        Politique de confidentialité
                    </h1>
                    <h2 className="mentions__container__section__div__h2">
                        Définitions
                    </h2>
                    <p className="mentions__container__section__div__p">
                        <strong>L'Éditeur</strong> : La personne, physique ou
                        morale, qui édite les services de communication au
                        public en ligne.
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>Le Site</strong> : L'ensemble des sites, pages
                        Internet et services en ligne proposés par l'Éditeur.
                    </p>
                    <p className="mentions__container__section__div__p">
                        <strong>L'Utilisateur</strong> : La personne utilisant
                        le Site et les services.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        1. Nature des données collectées
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Dans le cadre de l'utilisation des Sites, l'Éditeur
                            est susceptible de collecter les catégories de
                            données suivantes concernant ses Utilisateurs :{' '}
                        </strong>
                    </p>
                    <p className="mentions__container__section__div__p">
                        Données d'état-civil, d'identité, d'identification...
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        2. Communication des données personnelles à des tiers
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>Pas de communication à des tiers</strong>
                    </p>
                    <p className="mentions__container__section__div__p">
                        Vos données ne font l'objet d'aucune communication à des
                        tiers. Vous êtes toutefois informés qu'elles pourront
                        être divulguées en application d'une loi, d'un règlement
                        ou en vertu d'une décision d'une autorité réglementaire
                        ou judiciaire compétente.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        3. Collecte des données d'identité
                    </h2>
                    <p className="mentions__container__section__div__p">
                        <strong>Consultation libre</strong>
                    </p>
                    <p className="mentions__container__section__div__p">
                        La consultation du Site ne nécessite pas d'inscription
                        ni d'identification préalable. Elle peut s'effectuer
                        sans que vous ne communiquiez de données nominatives
                        vous concernant (nom, prénom, adresse, etc). Nous ne
                        procédons à aucun enregistrement de données nominatives
                        pour la simple consultation du Site.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        4. Collecte des données d'identification
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Utilisation de l'identifiant de l’utilisateur
                            uniquement pour l’accès aux services
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Nous utilisons vos identifiants électroniques seulement
                        pour et pendant l'exécution du contrat.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        5. Collecte des données du terminal
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Aucune collecte des données techniques
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Nous ne collectons et ne conservons aucune donnée
                        technique de votre appareil (adresse IP, fournisseur
                        d'accès à Internet...).
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        6. Cookies
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>Durée de conservation des cookies</strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Conformément aux recommandations de la CNIL, la durée
                        maximale de conservation des cookies est de 13 mois au
                        maximum après leur premier dépôt dans le terminal de
                        l'Utilisateur, tout comme la durée de la validité du
                        consentement de l’Utilisateur à l’utilisation de ces
                        cookies. La durée de vie des cookies n’est pas prolongée
                        à chaque visite. Le consentement de l’Utilisateur devra
                        donc être renouvelé à l'issue de ce délai
                    </p>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>Finalité cookies</strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Les cookies peuvent être utilisés pour des fins
                        statistiques notamment pour optimiser les services
                        rendus à l'Utilisateur, à partir du traitement des
                        informations concernant la fréquence d'accès, la
                        personnalisation des pages ainsi que les opérations
                        réalisées et les informations consultées.
                    </p>
                    <p className="mentions__container__section__div__p">
                        Vous êtes informé que l'Éditeur est susceptible de
                        déposer des cookies sur votre terminal. Le cookie
                        enregistre des informations relatives à la navigation
                        sur le service (les pages que vous avez consultées, la
                        date et l'heure de la consultation...) que nous pourrons
                        lire lors de vos visites ultérieures.
                    </p>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Droit de l'Utilisateur de refuser les cookies
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Vous reconnaissez avoir été informé que l'Éditeur peut
                        avoir recours à des cookies. Si vous ne souhaitez pas
                        que des cookies soient utilisés sur votre terminal, la
                        plupart des navigateurs vous permettent de désactiver
                        les cookies en passant par les options de réglage.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        7. Conservation des données techniques
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Durée de conservation des données techniques
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Les données techniques sont conservées pour la durée
                        strictement nécessaire à la réalisation des finalités
                        visées ci-avant.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        8. Délai de conservation des données personnelles et
                        d'anonymisation
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>Pas de conservation des données</strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Les données techniques sont conservées pour la durée
                        strictement nécessaire à la réalisation des finalités
                        visées ci-avant.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        9. Portabilité des données
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>Portabilité des données</strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        L'Éditeur s'engage à vous offrir la possibilité de vous
                        faire restituer l'ensemble des données vous concernant
                        sur simple demande. L'Utilisateur se voit ainsi garantir
                        une meilleure maîtrise de ses données, et garde la
                        possibilité de les réutiliser. Ces données devront être
                        fournies dans un format ouvert et aisément réutilisable.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        10. Modification de la politique de confidentialité
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            En cas de modification de la présente Politique de
                            Confidentialité, engagement de ne pas baisser le
                            niveau de confidentialité de manière substantielle
                            sans l'information préalable des personnes
                            concernées
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        Nous nous engageons à vous informer en cas de
                        modification substantielle de la présente Politique de
                        Confidentialité, et à ne pas baisser le niveau de
                        confidentialité de vos données de manière substantielle
                        sans vous en informer et obtenir votre consentement.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        11. Droit applicable et modalités de recours
                    </h2>
                    <p className="mentions__container__section__div__p">
                        {' '}
                        <strong>
                            Application du droit français (législation CNIL) et
                            compétence des tribunaux
                        </strong>{' '}
                    </p>
                    <p className="mentions__container__section__div__p">
                        La présente Politique de Confidentialité et votre
                        utilisation du Site sont régies et interprétées
                        conformément aux lois de France, et notamment à la Loi
                        n° 78-17 du 6 janvier 1978 relative à l'informatique,
                        aux fichiers et aux libertés. Le choix de la loi
                        applicable ne porte pas atteinte à vos droits en tant
                        que consommateur conformément à la loi applicable de
                        votre lieu de résidence. Si vous êtes un consommateur,
                        vous et nous acceptons de se soumettre à la compétence
                        nonexclusive des juridictions françaises, ce qui
                        signifie que vous pouvez engager une action relative à
                        la présente Politique de Confidentialité en France ou
                        dans le pays de l'UE dans lequel vous vivez. Si vous
                        êtes un professionnel, toutes les actions à notre
                        encontre doivent être engagées devant une juridiction en
                        France
                    </p>
                    <p className="mentions__container__section__div__p">
                        En cas de litige, les parties chercheront une solution
                        amiable avant toute action judiciaire. En cas d'échec de
                        ces tentatives, toutes contestations à la validité,
                        l'interprétation et / ou l'exécution de la Politique de
                        Confidentialité devront être portées même en cas de
                        pluralité des défendeurs ou d'appel en garantie, devant
                        les tribunaux français.
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Pdc
