import imgA from '../../Assets/pexels-eberhard-grossgasteiger-6.webp'

function Cgv() {
    return (
        <div className="mentions__container" id="hello">
            <div className="mentions__container__div">
                <img
                    src={imgA}
                    alt="Fond de montagne"
                    className="Prestation__container__div__img"
                ></img>
            </div>
            <section className="mentions__container__section">
                <div className="mentions__container__section__div">
                    <h1 className="mentions__container__section__div__h1">
                        Conditions Générales des ventes
                    </h1>
                    <h2 className="mentions__container__section__div__h2">
                        Article 1: les parties
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le mot "Client" fait référence à toute personne physique
                        ou morale qui a fait appel aux services ou produits
                        proposés par le Prestataire. Le terme "Prestataire"
                        désigne la société GT-ATWEB, EI, située au 9 impasse des
                        palmiers, Seine-Maritime, 76610 Le Havre, immatriculée
                        au RCS du Havre sous le numéro 948 806 492 et dirigée
                        par Teddy Guerrand en tant que Gérant.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 2: Contrat
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le contrat définit les termes et les conditions de la
                        relation commerciale entre le Prestataire et le Client.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 3: Introduction et validation du contrat.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le Prestataire se réserve le droit de changer ses
                        conditions générales de vente ainsi que les tarifs
                        proposés à tout moment, sans préavis. Cependant, les
                        devis et les bons de commande qui ont été émis avant la
                        modification des conditions générales de vente ne seront
                        pas affectés par ces changements. Si le Client est un
                        particulier, il doit être majeur conformément aux lois
                        de son pays de résidence. En acceptant un devis ou un
                        bon de commande émis par le Prestataire, le Client
                        confirme qu'il a pris connaissance des conditions
                        générales de vente et les accepte.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 4: Changements apportés à la demande initiale
                        par le Client.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Si le Client souhaite ajouter une nouvelle prestation
                        qui n'était pas incluse dans le devis initial, le
                        Prestataire établira un nouveau devis pour cette
                        prestation. Si la durée de la prestation initiale est
                        significativement prolongée en raison de demandes du
                        Client ou de données non fournies avant l'établissement
                        du devis, le Prestataire facturera une tarification
                        journalière basée sur la tarification journalière du
                        devis initial. Les modifications significatives, les
                        ajouts de développement, les retards dans la prise de
                        décision ou la livraison tardive de documents par le
                        Client nécessaires à la prestation retarderont la date
                        de livraison initialement prévue convenue entre les
                        parties.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 5: Cession des droits.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le transfert complet des droits d'utilisation et de
                        modification des développements créés par le Prestataire
                        ne sera effectif qu'à la réception complète des montants
                        facturés, à moins que des dispositions contraires ne
                        soient mentionnées sur le devis et/ou la facture. La
                        cession des droits sera détaillée et précisée pour
                        chaque projet sur le devis et/ou la facture, en fonction
                        des accords convenus entre les deux parties. Sauf
                        mention contraire sur le devis et/ou la facture, le
                        paiement complet de la facture entraînera la cession des
                        droits d'utilisation, de modification et de diffusion
                        des développements pour le Client.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 6: Confidentialité.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le Prestataire est tenu de respecter la confidentialité
                        avant et pendant toute la durée du projet, en
                        s'engageant à ne pas divulguer à des tiers les
                        informations ou documents relatifs au Client et à son
                        projet, quelles qu'en soient la nature et la forme.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 7: Modalités de paiement.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Sauf si des dispositions contraires sont mentionnées
                        dans le devis et/ou la facture, le client sera tenu de
                        verser un acompte d'au moins 30% du montant total TTC
                        lors de la commande. Les prestations fournies par le
                        prestataire devront être réglées par le client aux dates
                        précisées sur le devis et/ou la facture. Le client doit
                        respecter les échéances de paiement indiquées sur sa
                        facture et doit renvoyer la facture datée et signée avec
                        la mention « Lu et approuvé ». Cette clause est
                        considérée comme un élément essentiel du contrat.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 8: Recouvrement des créances.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Si le client ne procède pas au paiement des factures
                        dans les délais impartis, tous les frais de recouvrement
                        seront à sa charge. Dans ce cas, le prestataire pourra
                        engager les services d'une société d'affacturage pour
                        récupérer les sommes dues.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 9: Cas de force majeure.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Les parties ne seront pas considérées comme responsables
                        ou ayant manqué à leurs obligations contractuelles en
                        cas de force majeure. Le contrat sera suspendu jusqu'à
                        la fin des événements à l'origine de la force majeure.
                        La force majeure inclut des événements ou des
                        circonstances imprévisibles et indépendantes de la
                        volonté des parties, malgré tous les efforts
                        raisonnables pour les empêcher. Les exemples de force
                        majeure incluent les catastrophes naturelles telles que
                        les tremblements de terre, les incendies, les tempêtes,
                        les inondations, les éclairs, les pannes de transport ou
                        d'approvisionnement, la cessation des réseaux de
                        télécommunication, y compris les réseaux accessibles par
                        Internet, ainsi que toutes les difficultés liées aux
                        réseaux de télécommunication extérieurs aux parties. La
                        partie affectée par la force majeure informera l'autre
                        partie dans un délai de cinq jours ouvrables à compter
                        de la date à laquelle elle en a pris connaissance. Les
                        deux parties conviendront alors des modalités de
                        poursuite de l'exécution du contrat.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 10: Les conditions générales de vente.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le prestataire se réserve le droit de modifier, ajouter
                        ou supprimer des clauses aux présentes conditions
                        générales de vente sans préavis et sans obligation de
                        notifier ses clients ou des tiers au préalable.
                    </p>
                    <h2 className="mentions__container__section__div__h2">
                        Article 11: Règlement des litiges.
                    </h2>
                    <p className="mentions__container__section__div__p">
                        Le contrat est régi par la loi française. En cas de
                        litige, les parties s'efforceront de résoudre leur
                        différend à l'amiable. Si une solution amiable ne peut
                        être trouvée, la juridiction compétente sera celle de Le
                        Havre.
                    </p>
                </div>
            </section>
        </div>
    )
}

export default Cgv
