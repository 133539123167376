import ImgA from '../../Assets/Wavy_Tech-01_Single-10_prev_ui.webp'
import ImgB from '../../Assets/20944157_prev_ui.webp'
import ImgC from '../../Assets/20944036_prev_ui.webp'
import ImgD from '../../Assets/flat-lay-desktop-with-laptop-magnifier.webp'
import ImgE from '../../Assets/icon-left-font_prev_ui.webp'
import ImgF from '../../Assets/logo_prev_ui.webp'

function Home() {
    return (
        <div className="home__container" id="hello">
            <section className="home__container__section1 ">
                <span className="home__container__section1__div1__transparence"></span>
                <div className="home__container__section1__div2">
                    <h1 className="home__container__section1__div2__h1">
                        Création de site web
                    </h1>
                    <h1 className="home__container__section1__div2__h1--2">
                        Vous recherchez un développeur web ?
                    </h1>
                    <a href="/Contact" className="btn-6">
                        <span>Contactez-nous</span>
                    </a>
                </div>
                <div className="home__container__section1__div1">
                    <img
                        className="home__container__section1__div1__image"
                        id="imgHeight"
                        src={ImgD}
                        alt="illustration Bureau, développement web"
                    />
                </div>
                <div className="home__container__section1__div3">
                    <a
                        className="home__container__section1__div3__a"
                        href="#Accueil"
                    >
                        <i
                            className="fa-solid fa-chevron-down home__container__section1__div3__a__i"
                            id="Accueil"
                        ></i>
                        <span className="sr-only">
                            descendre a la première section
                        </span>
                    </a>
                </div>
            </section>
            <section className="home__container__section2">
                <h2 className="home__container__section2__h2">Nos services</h2>
                <p className="home__container__section2__p">
                    Vous souhaitez augmenter votre{' '}
                    <b>visibilité sur internet</b> pour promouvoir d'avantage
                    votre entreprise ?
                </p>
                <p className="home__container__section2__p2">
                    Pour atteindre cet objectif, il est crucial de disposer d'un{' '}
                    <b>site web efficace, performant et adapté</b> aux besoins
                    de votre domaine d'activité.
                </p>
                <div className="home__container__section2__wrapper">
                    <div className="home__container__section2__wrapper__div">
                        <img
                            src={ImgA}
                            alt="illustration Création de Site Web"
                            className="home__container__section2__wrapper__div__img"
                        ></img>
                        <h3 className="home__container__section2__wrapper__div__h3">
                            Création de site web
                        </h3>
                        <p className="home__container__section2__wrapper__div__p">
                            Création de sites web personnalisés pour{' '}
                            <b>une expérience optimale</b> sur tous les écrans.
                            Site professionnel dédié, <b> sans publicités</b> ni
                            restrictions, <b>adapté à votre entreprise</b> et{' '}
                            <b>100% propriétaire</b>.
                        </p>
                    </div>
                    <div className="home__container__section2__wrapper__div">
                        <img
                            src={ImgB}
                            alt="illustration Référencement Naturel"
                            className="home__container__section2__wrapper__div__img"
                        ></img>
                        <h3 className="home__container__section2__wrapper__div__h3">
                            Référencement Naturel
                        </h3>
                        <p className="home__container__section2__wrapper__div__p">
                            Présentations en ligne professionnelles sur les
                            réseaux sociaux. Publicité ciblée sur Google pour{' '}
                            <b>stimuler votre présence en ligne</b> et{' '}
                            <b>améliorer la visibilité</b> de votre site web sur
                            les moteurs de recherche.
                        </p>
                    </div>
                    <div className="home__container__section2__wrapper__div">
                        <img
                            src={ImgC}
                            alt="illustration Gestion de Site Web"
                            className="home__container__section2__wrapper__div__img"
                        ></img>
                        <h3 className="home__container__section2__wrapper__div__h3">
                            Gestion de votre site Web
                        </h3>
                        <p className="home__container__section2__wrapper__div__p">
                            Nous assurons la <b>maintenance</b>, la{' '}
                            <b>modernisation</b> et la <b>visibilité</b> de
                            votre site web pour une{' '}
                            <b>présence en ligne optimale</b>. Faites-nous
                            confiance pour relancer votre site.
                        </p>
                    </div>
                </div>
                <a
                    href="/Nos-prestations"
                    className="home__container__section2__a"
                >
                    En savoir plus
                </a>
            </section>
            <section className="home__container__section3">
                <div className="home__container__section3__bloc">
                    <p className="home__container__section3__bloc__p">
                        Si vous avez besoin d'aide pour{' '}
                        <b>créer votre site internet</b> et mettre en avant
                        votre entreprise, nous sommes là pour vous. Nous
                        disposons des compétences nécessaires en tant que
                        développeur web pour réaliser votre projet, que ce soit
                        en termes de développement, de référencement ou
                        d'hébergement web.
                    </p>
                </div>
                <div className="home__container__section3__div">
                    <div className="home__container__section3__div__triangle__top"></div>
                    <div className="home__container__section3__div__ph">
                        {' '}
                        <h2 className="home__container__section3__div__ph__h2">
                            Notre philosophie
                        </h2>
                        <p className="home__container__section3__div__ph__p">
                            <b>Nous concevons des sites web personnalisés</b> en
                            prenant en compte vos objectifs commerciaux et
                            stratégiques pour refléter l'image de votre
                            entreprise. Nous mettons en place une{' '}
                            <b>stratégie digitale rentable à long terme</b>, en
                            veillant à la qualité, au coût, au référencement, à
                            la responsivité pour les smartphones, à la sécurité
                            et à la maintenance régulière.
                        </p>
                    </div>

                    <div className="home__container__section3__div__triangle"></div>
                </div>
            </section>
            <section className="home__container__section4">
                <h2 className="home__container__section4__h2">
                    Nos réalisations
                </h2>
                <div className="home__container__section4__wrapper">
                    <div className="home__container__section4__wrapper__divG">
                        <div className="home__container__section4__wrapper__divG__G">
                            <img
                                src={ImgE}
                                alt="Groupomania réseaux social"
                                className="home__container__section4__wrapper__divG__G__img"
                            ></img>
                        </div>
                        <div className="home__container__section4__wrapper__divG__D">
                            <h3 className="home__container__section4__wrapper__divG__D__h3">
                                (Réseau social) Groupomania
                            </h3>
                            <h4 className="home__container__section4__wrapper__divG__D__h4">
                                Projet professionnel de formation
                            </h4>
                            <p className="home__container__section4__wrapper__divG__D__p">
                                Utilisation de{' '}
                                <b>Node.js, MongoDB et React.js</b> en
                                travaillant sur un projet professionnel de
                                réseau social. Mise en pratique{' '}
                                <b>d'intégration de bases de données </b> mDB et
                                créer une{' '}
                                <b>interfaces utilisateur dynamiques.</b>
                            </p>
                            <a
                                href="https://github.com/TeddyGrd/OC_Groupomania"
                                target={'_blank'}
                                rel="noreferrer"
                                className="home__container__section4__wrapper__divG__D__a"
                            >
                                Voir le code
                            </a>
                        </div>
                    </div>
                    <div className="home__container__section4__wrapper__divD">
                        <div className="home__container__section4__wrapper__divD__G">
                            <img
                                src={ImgF}
                                alt="Kanap site e-commerce"
                                className="home__container__section4__wrapper__divD__G__img"
                            ></img>
                        </div>
                        <div className="home__container__section4__wrapper__divD__D">
                            {' '}
                            <h3 className="home__container__section4__wrapper__divD__D__h3">
                                (site web e-commerce) Kanap
                            </h3>
                            <h4 className="home__container__section4__wrapper__divD__D__h4">
                                Projet professionnel de formation
                            </h4>
                            <p className="home__container__section4__wrapper__divD__D__p">
                                Utilisation de <b>Node.js, MongoDB</b> pour
                                travailler sur se projet professionnel de{' '}
                                <b>site web e-commerce.</b> Manipulations des
                                API afin de créer un{' '}
                                <b>site e-commerce moderne et efficace.</b>
                            </p>
                            <a
                                href="https://github.com/TeddyGrd/OC_Kanap"
                                target={'_blank'}
                                rel="noreferrer"
                                className="home__container__section4__wrapper__divG__D__a"
                            >
                                Voir le code
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home
